import { useEffect, useState } from 'react'
import {  QuestionMarkCircleIcon, XIcon as XIconSolid } from '@heroicons/react/solid'
import { CartItem } from '../../contracts/response-models/cart';
import { addToCartService, createOrderSummaryService, deleteCartItemService, getCartItemsService } from '../../machines/admin/services';
import { AddToCartRequest } from '../../contracts/request-models/add-to-cart';
import { localStorageProducts} from '../../api/client';
import { CreateOrderRequest } from '../../contracts/request-models/create-order';
import { currencyType } from '../../utils/currency-type';
import { GetLocalLangValue } from '../../utils/language';
import { Link } from 'react-router-dom';

export default function ShoppingCart() {

    const [updatingCart,setUpdatingCart] = useState(false);
    const [cartChanged,setCartChanged] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [cartItems, setCartitems] = useState<CartItem[]>();
    const [subTotal,setSubTotal]=useState<number>(0);
    const [shippingEstimate,setShippingEstimate]=useState(0.00);
    const [taxEstimate,setTaxEstimatel]=useState(0.00);
    const [total,setTotal]=useState<number>(0);
    const [quantity,setQuantity]=useState(1);
    const [error,setError]=useState('');
    const priceType=window.localStorage.getItem('__currency_type__');
    const [memo,setMemo]=useState('');
    var itemCount=(window.localStorage.getItem(localStorageProducts))?.length;

    
        useEffect(() => {
        async function loadCartInfo() {     
            
            setIsLoading(true);
            const result = await getCartItemsService();
            setCartitems(result.data) ;
            var calculatedSubTotal
            if(priceType=='Yuan')
            { 
                calculatedSubTotal = result.data?.reduce((sum, current) => sum + (current.price)*current.quantity, 0);
                
            }
            else{
                calculatedSubTotal = result.data?.reduce((sum, current) => sum + (current.usd_price)*current.quantity, 0);    
                
            }
            setSubTotal(calculatedSubTotal);
            setTotal(calculatedSubTotal+shippingEstimate+taxEstimate);    
            setIsLoading(false);
            
        }
        
        loadCartInfo();
        
      }, [cartChanged]);
      
    const deleteItem = async (pid: any) => {
        setUpdatingCart(true);        
        const rsp = await deleteCartItemService(pid);
        setCartChanged(!cartChanged);
    }
    const createOrder = async () => {
        setUpdatingCart(true);
        
        try{
            let request: CreateOrderRequest={
                memo:memo
            }        
            const rsp = await createOrderSummaryService(request);
            setError('Order request created successfully')   ;
            setTimeout(() => {
                setCartChanged(!cartChanged);  } , 2000)
        }
        catch(e:any){
            setError(e.message);
        }
        setUpdatingCart(false);  
        
    }
    const updateQuantity = async (pid: any,q:number) => {
        setIsLoading(true);
        let request: AddToCartRequest={}
        await addToCartService(pid,q,request)
        setCartChanged(!cartChanged);
        
    }
  

  return (
    <div className="bg-white">
        <main className="max-w-2xl mx-auto pt-6 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className='w-full '>
                <h1 className="text-3xl sm:inline-flex tracking-tight text-gray-900 sm:text-2xl">{GetLocalLangValue("ShoppingCart")}</h1>
                <div className="float-right">
                <a href='/'  className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    {GetLocalLangValue("ContinueShopping")}<span aria-hidden="true"> &rarr;</span>
                </a>
            
                </div>
            </div>
            {(cartItems && cartItems?.length>0) ?(      
            <div className="mt-8 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
              
                <section aria-labelledby="cart-heading" className="lg:col-span-7">
                 
                    <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                    </h2>
                    
                    <ul role="list" className="border-t border-b border-gray-200 divide-y divide-gray-200">
                    {cartItems && cartItems.map((item) => (
                    <li key={item.product_id} className="flex py-6 sm:py-10">
                    <div className="flex-shrink-0">
                        <Link to={`/products?id=${item.product_id}`}>
                            <img
                            src={"https://api.lihongart.com/storage/uploads/thumbnail/"+item.sku_id+".png"}
                            className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                            />
                        </Link>    
                    </div>

                    <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                        <div>
                            <div className="flex justify-between">
                                <h3 className="text-sm">
                                    <a href={"/products?id="+item.product_id} className="text-lg font-bold text-gray-500 hover:text-gray-900">
                                        {item.title}
                                    </a>
                                </h3>
                            </div>
                            <div className="mt-1 flex text-sm">
                            {/* {product.size ? (
                                <p className="ml-4 pl-4 border-l border-gray-200 text-gray-500">{product.size}</p>
                            ) : null} */}
                            </div>
                            {priceType=='Yuan' ? (<>
                            <p className="mt-1 text-md font-medium text-gray-900">¥ {item.price}</p>
                            <br/>
                            <p className="mt-1 text-md font-medium text-gray-900">{GetLocalLangValue("Subtotal")} :  ¥ {item.price*item.quantity}</p></>):(<>
                            <p className="mt-1 text-md font-medium text-gray-900">$ {item.usd_price}</p>
                            <br/>
                            <p className="mt-1 text-md font-medium text-gray-900">{GetLocalLangValue("Subtotal")} :  $ {item.usd_price*item.quantity}</p>
                            </>)}
                        </div>

                        <div className="mt-4 sm:mt-0 sm:pr-9">
                            <label className="pr-4">
                            {GetLocalLangValue("Quantity")} :
                            </label>
                            {isLoading ? ( <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-black" viewBox="0 0 24 24"></svg>):(<input
                            type='text'
                            defaultValue={item.quantity}
                            onChange={(e)=>{
                               
                                setQuantity(parseInt(e.target.value))
                            }}
                            onBlur={()=>updateQuantity(item.product_id,quantity)}
                            
                           
                            className="max-w-12 w-12 rounded-md border border-gray-300 py-1.5 pl-3 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />)}
                            
                           

                            <div className="absolute top-0 right-0">
                            <button type="button" className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                            onClick={()=>{deleteItem(item.product_id)}}>
                                <span className="sr-only">Remove</span>
                                <XIconSolid className="h-5 w-5" aria-hidden="true" />
                            </button>
                            </div>
                        </div>
                        
                        </div>

                        <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                        {/* {product.inStock ? (
                            <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        ) : (
                            <ClockIcon className="flex-shrink-0 h-5 w-5 text-gray-300" aria-hidden="true" />
                        )} */}

                        {/* <span>{product.inStock ? 'In stock' : `Ships in ${product.leadTime}`}</span> */}
                        </p>
                    </div>
                   
                    </li>
                ))}
                
            </ul>

                </section>

                {/* Order summary */}
                <section
                    aria-labelledby="summary-heading"
                    className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
                >
                    <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                    {GetLocalLangValue("OrderSummary")}
                    </h2>

                    <dl className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                        <dt className="text-sm text-gray-600">{GetLocalLangValue("Subtotal")}Subtotal</dt>
                        <dd className="text-sm font-medium text-gray-900">{currencyType(subTotal)}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="flex items-center text-sm text-gray-600">
                        <span>{GetLocalLangValue("ShippingEstimate")}</span>
                        <a href="" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Learn more about how shipping is calculated</span>
                            <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">{currencyType(shippingEstimate)}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="flex text-sm text-gray-600">
                        <span>{GetLocalLangValue("TaxEstimate")}</span>
                        <a href="" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Learn more about how tax is calculated</span>
                            <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">{currencyType(taxEstimate)}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">{GetLocalLangValue("OrderTotal")}</dt>
                        <dd className="text-base font-medium text-gray-900">{currencyType(total)}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                    <textarea
                      placeholder={GetLocalLangValue("Memo")}
                      className="max-w-full resize-none w-full rounded-md border border-gray-300 py-1.5 pl-3 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e)=>setMemo(e.target.value)}
                    />
                    </div>
                    </dl>
                       
                    <div className="mt-6">
                      <button
                        type="submit"
                        className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                        onClick={()=>{
                            createOrder()}}
                        >
                         {updatingCart && ( <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-black" viewBox="0 0 24 24"></svg>)   }
                        {GetLocalLangValue("Checkout")}
                      </button>
                      {error && (
                          <div className="py-3">
                            <span >{error}</span>
                          </div>
                            )}   
                    </div>
                </section>
            </div>):(
                <div >
                    <p className='max-w-max justify-self-center text-lg  pt-16' >
                       {GetLocalLangValue("EmptyCart")}
                    </p>
                </div>  )  }
            
        </main>

     
    </div>
  )
}