import OrderSummaryForm from '../components/OrderSummary';



 export default function OrderSummaryPage () {
     
     return(
        
         <OrderSummaryForm/>
        
     )
 }