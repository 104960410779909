import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'


export default function PriceType() {
   var label=window.localStorage.getItem('__currency_type__')
  return (
    <Popover className="relative ml-2 px-2 py-2">
     
        <>
          <Popover.Button
            className='text-gray-900 text-gray-500
              group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            
          >
            <span>{label}</span>
            <ChevronDownIcon
              className='text-gray-600 text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500'
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-max sm:px-0">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  
                   <a role="button" className="text-base font-medium text-gray-500 hover:text-gray-900"
                   onClick={()=>
                    {   
                        
                        window.localStorage.setItem("__currency_type__",'USD');
                        window.location.reload();
                        
                    }}
                   >
                    USD
                   </a>
                   <a role="button" className="text-base font-medium text-gray-500 hover:text-gray-900"
                    onClick={()=>
                    {   
                      
                      window.localStorage.setItem("__currency_type__",'Yuan');
                      window.location.reload();
                      
                    }}
                   >
                    Yuan
                   </a>
                  
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
     
    </Popover>
  )
}