import { validateCell } from "../../api/auth/validate-cell";
import { verifyOTP } from "../../api/auth/verify-otp";
import {  createOrderSummary } from "../../api/order-summary/create-summary";
import { listImportLogs } from "../../api/import-log/list";
import { createProduct } from "../../api/products/create";
import { deleteProduct } from "../../api/products/delete";
import { getProductInfo } from "../../api/products/get";
import { homePageList } from "../../api/products/homepagelist";
import { importProduct } from "../../api/products/import";
import { listProducts } from "../../api/products/list";
import { patchProduct } from "../../api/products/patch";
import { listSeries } from "../../api/series/list";
import { addToCart } from "../../api/shopping-cart/add-product";
import { deleteCartItem } from "../../api/shopping-cart/delete-cart";
import {  getCartItems } from "../../api/shopping-cart/get-cart";
import { getProfile } from "../../api/user/get-profile";
import { patchProfile } from "../../api/user/patch-user";
import { AddToCartRequest} from "../../contracts/request-models/add-to-cart";
import { CreateOrderRequest } from "../../contracts/request-models/create-order";
import { CreateProductRequest } from "../../contracts/request-models/create-product"; 
import { ImportProductRequest } from "../../contracts/request-models/import-product-csv";
import { PatchProductRequest } from "../../contracts/request-models/patch-product";
import { PatchProfileRequest } from "../../contracts/request-models/patch-profile";
import { Product } from "../../contracts/response-models/product";
import { getOrderSummary } from "../../api/order-summary/get-summary";
import { getOrderInfo } from "../../api/order-summary/orders";
import { checkCartProducts } from "../../api/shopping-cart/check-cart";

export const createProductService = async (product: Product) => {
  let request: CreateProductRequest = {
    title: product.title, 
    meta_title:product.meta_title,
    meta_tag_keywords:product.meta_tag_keywords,
    meta_tag_description:product.meta_tag_description,
    sku_id:product.sku_id,
    size:product.size,
    price:product.price,
    description: product.description,
    nw: product.nw,
    gw: product.gw,
    height: product.height,
    cuft: product.cuft,
    imageNames:product.imageNames,
    status:product.status,
  };
  const createResponse = await createProduct(request);
  return createResponse;
};

export const listProductsService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const products = await listProducts(paged, perpagearecord, sortedColumn, sortDirection);
  return products;
};

export const listProductsForHomePage = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string,searchkey:string,categories?:string) => {
  const products = await homePageList(paged, perpagearecord, sortedColumn, sortDirection,searchkey,categories);
  return products;
};

export const getProductInfoService = async (id: number) => {
  const productinfo = await getProductInfo(id);
  return productinfo;
};

export const editProductService = async (product: Product,pid:number) => {
  let request: PatchProductRequest = {
    id:product.id,
    title: product.title, 
    meta_title:product.meta_title,
    meta_tag_keywords:product.meta_tag_keywords,
    meta_tag_description:product.meta_tag_description,
    sku_id:product.sku_id,
    size:product.size,
    price:product.price,
    description: product.description,
    nw: product.nw,
    gw: product.gw,
    height: product.height,
    cuft: product.cuft,
    imageNames:product.imageNames,
    status:product.status,
  };
  const createResponse = await patchProduct(pid,request);
  return createResponse;
};

export const DeleteProductService = async (pid: any) => {
  const post = await deleteProduct(pid);
  return post;
};

export const importProductService = async (csv: File) => {
  let request: ImportProductRequest = {
    csvfile:csv,
    
  };
  const createResponse = await importProduct(request);
  return createResponse;
};

export const listLogService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const importlog = await listImportLogs(paged, perpagearecord, sortedColumn, sortDirection);
  return importlog;
};

export const validateCellService = async (cell:string) => {
  const rsp = await validateCell(cell);
  return rsp;
};

export const validateOTPService = async (cell:string,code:string) => {
  const rsp = await verifyOTP(cell,code);
  return rsp;
};

export const patchUserProfileService = async (userId:number,patchProfileRequest: PatchProfileRequest) => {
  const rsp = await patchProfile(userId,patchProfileRequest);
  return rsp;
};

export const getUserProfileService = async () => {
  const productinfo = await getProfile();
  return productinfo;
};

export const getSeriesService = async () => {
  const seriesList = await listSeries();
  return seriesList;
};

export const addToCartService = async (product_id:any,quantity: number,request:AddToCartRequest) => {
  const saveCart = await addToCart(product_id,quantity,request);
  return saveCart;
};

export const getCartItemsService = async () => {
  const getCart = await getCartItems();
  return getCart;
};

export const checkCartItemService = async (request:any) => {
  const checkCart = await checkCartProducts(request);
  return checkCart;
};

export const deleteCartItemService = async (product_id:number) => {
  const deleteItem = await deleteCartItem(product_id);
  
  return deleteItem;
};

export const createOrderSummaryService = async (request:CreateOrderRequest) => {
  const saveOrder = await createOrderSummary(request);
  return saveOrder;
};

export const getOrderSummaryService = async () => {
  const getOrder = await getOrderSummary();
  return getOrder;
};

export const getOrderService = async (order_id:number) => {
  const getOrder = await getOrderInfo(order_id);
  return getOrder;
};