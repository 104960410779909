import React from "react";
    
export default function Productloading() {
    return (
        
            
                <div
                className="group relative bg-white border border-gray-200 rounded-lg animate-pulse flex flex-col overflow-hidden"
                >
                    <div  className="  aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96 cursor-pointer">
                        
                    </div>
                    <div className="flex-1 p-4 space-y-2 flex flex-col">    
                        <div className="h-4 bg-gray-200 rounded"></div>

                        <div className="h-4 bg-gray-200 rounded"></div>
                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                    </div>
                </div>
            
            
        
    )
}