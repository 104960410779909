import { NavLink } from "react-router-dom";

export default function Navigation(){
    return (
<>
    <a href='' className="text-base font-medium text-gray-500 hover:text-gray-900">
        
    </a>
    
</>
    )
}