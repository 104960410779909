import { client } from "../client";

import { PatchProfileRequest } from "../../contracts/request-models/patch-profile";
import { ActionResponse } from "../../contracts/response-models/action-response";



export function patchProfile(userid: number, request: PatchProfileRequest) {
    
    //return client<ActionResponse<string>>(`/save-profile/${userid}`, request, {method: "PATCH"});
    return client<ActionResponse<string>>(`/save-profile`, request, {method: "POST"});

}
    