import { Dialog, Transition } from '@headlessui/react';
import {Fragment, useState} from 'react';
import {XIcon} from '@heroicons/react/outline';
import { AddToCartRequest } from "../../contracts/request-models/add-to-cart";
import { ProductFront } from "../../contracts/response-models/productfront";
import { addToCartService} from "../../machines/admin/services";
import { roundUp } from '../../utils/round';
import { GetLocalLangValue } from '../../utils/language';

interface ProductQuantityParam{
    product?:ProductFront;
    open:boolean;
    setOpen:any;
    view:boolean;
}



export default function QuantityModal({product,open,setOpen,view}:ProductQuantityParam){
  const [quantity,setQuantity]=useState<number>(roundUp(product?.usd_price));
  
    
  const [isLoading,setIsLoading]=useState(false);
  const [error,setError] =useState('');
  const [itemCount,setItemCount]=useState(0);

  

  const addToCart = async ()=>{
    
    setError('');
    setIsLoading(true);
    let request:AddToCartRequest={}
    
    try{
      

      const result = await addToCartService(product?.id,quantity,request);           
      setError(result.message);
      setTimeout(() => {setOpen(!open);} , 1000);
      

    } 
    catch (e:any) {
  
      setError(e.message);

    }
    setIsLoading(false);  
}
 return(
    <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>   
                  <div className="bg-white py-3 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="space-y-6" >
                      {view ? (<label>{GetLocalLangValue("AlreadyInCart")}</label>):(<><div>
                        <label className="block text-sm font-medium text-gray-700">
                        {GetLocalLangValue("EnterTheQuantity")}
                        </label>
                        <div className="mt-1">
                        <input
                            onChange={(e)=>{
                              if(isNaN(parseInt(e.target.value)))
                                  return;
                                  
                              setQuantity(parseInt(e.target.value));
                            }}
                            defaultValue={quantity}
                            type="number"
                            min="1"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        </div>
                        
                      </div>            
                      <div >
                        <div className='pl-20'>
                          <button
                            type="button"
                            className="object-center bg-indigo-600 border border-transparent rounded-md py-1 px-2 flex items-center justify-center text-base text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-30"
                            onClick={()=>{       
                              addToCart();
                              
                            }}
                          >
                          {isLoading && (
                                              <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                                              )} 
                          {GetLocalLangValue("ToCart")}</button>
                        </div>
                        <div className=" pt-3">            
                          {error && (
                            <span>{error}</span>
                          )}  
                        </div> 
                      </div></>)}
                    </div>
                    
                  </div>
                  
                </div>    
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
)
    
}