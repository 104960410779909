import { Route, Switch } from "react-router-dom";
import OrderDetails from "../components/OrderDetails";
import HomePage from "../pages/HomePage";
import OrderSummaryPage from "../pages/OrderSummaryPage";
import ProductDetailsPage from "../pages/ProductDetailPage";
import ProfilePage from "../pages/ProfilePage";
import ShoppingCartPage from "../pages/ShoppingCartPage";
import SignInPage from "../pages/SignInPage";
import PrivateRoute from "./PrivateRoute";


export default function Routes(){
    return(
          <Switch>
                     
              <Route exact path='/' component={HomePage}></Route>
              <Route exact path='/signInPage' component={SignInPage}/>
              <Route exact path='/products' component={ProductDetailsPage}/>
              <PrivateRoute exact path='/cart' component={ShoppingCartPage}/>              
              <PrivateRoute exact path='/profile' component={ProfilePage}></PrivateRoute>
              <PrivateRoute exact path='/order-summary' component={OrderSummaryPage}></PrivateRoute>
              <PrivateRoute exact path='/orders' component={OrderDetails}></PrivateRoute>      
                      
          </Switch>    
       ) 
}