import { useState } from 'react';
import { ProductFront } from '../../contracts/response-models/productfront';
import LoginModal from '../LoginModal'
import { hasValidToken } from '../../utils/has-valid-token';
import QuantityModal from '../ShoppingCart/QuantityModal';
import { checkCart } from '../../utils/check-cart-products';
import { GetLocalLangValue } from '../../utils/language';



interface ProductDetailInput{
  product?:ProductFront
  language:any
  priceType:any
}


export default function ProductDetails({product,language,priceType}:ProductDetailInput) {
  const [loginModal,setLoginModalOpen] = useState(false);
  const [quantityModal,setQuantityModalOpen]=useState(false);
  const [viewType,setViewType]=useState(false);

  

  
  if(quantityModal)
      return (
        <QuantityModal product={product} open={quantityModal} setOpen={setQuantityModalOpen} view={viewType}/>
  )
  if(loginModal)
      return (
        <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
  )
 
  return (
    
    <>  
   
         
            {/* Image selector */}
      <div className="w-full max-w-2xl mx-auto sm:block lg:max-w-none">

            <div className="w-full aspect-w-1 aspect-h-1 ">
              
                  <img
                    src={"https://api.lihongart.com/storage/uploads/"+product?.image?.[0]}
                    className="w-full h-full object-center object-cover border border-gray-300 sm:rounded-lg"
                  />
                
            </div>
          </div>
        {/* Product info */}
          <div className=" px-4 sm:px-0 sm:mt-16 lg:mt-0 ">
            <h1 className="text-3xl font-bold tracking-tight  text-gray-900">{product?.title}</h1>
        
            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              {!hasValidToken() ?
               (
                <p className="text-sm text-gray-500">
                <h2 className="text-xl font-medium text-gray-900 sm:pr-12" role="button" onClick={()=>(setLoginModalOpen(!loginModal))}>{GetLocalLangValue("SeePrice")}</h2>                          
              </p>
               )
                :(<>
                  {priceType=='Yuan' ? (
                    <p className="text-3xl text-gray-900">    ¥ {product?.price}</p>):(
                      <p className="text-3xl text-gray-900">    $ {product?.usd_price}</p>  
                    )}
                   </> 
                )
              }
            </div>

            {/* Reviews <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        product.rating > rating ? 'text-indigo-500' : 'text-gray-300',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{product.rating} out of 5 stars</p>
              </div>
            </div> */}

            <div className="mt-6 items-right">
              <h3 className="sr-only">Description</h3>
              <div className="text-base text-gray-700 content-right space-y-6">
                {language=='Chinese' ?(
                  <p className="items-right"> {  product?.description }</p>):
                  (<p className="items-right"> {  product?.description_english }</p>)
                }
              </div>
            </div>

            <div className="mt-6">
              <div className="mt-10 flex sm:flex-col1">
                <button
                  type="button"
                  className="bg-indigo-600 h-6 w-30 border border-transparent rounded-md px-2 text-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 max-w-max"
                  onClick={async ()=>{
                    setViewType(await checkCart(product?.id));
                    setQuantityModalOpen(!quantityModal);
                  }}
                >{GetLocalLangValue("ToCart")}
                </button>
              </div>
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                  <div className="border-t divide-y divide-gray-200"/>
                  {product?.size!=0 &&(
                  <div>
                    <p className="font-medium text-gray-900">{GetLocalLangValue("Size")}: {product?.size}</p>
                  </div>)}
                  <div className="mt-8">
                    <p className="font-medium text-gray-900">{GetLocalLangValue("Height")}: {product?.height}</p>  
                  </div>
                  <div className="mt-8">
                    <p className="font-medium text-gray-900">{GetLocalLangValue("NW")}: {product?.nw}</p>
                  </div>
                  <div className="mt-8">
                    <p className="font-medium text-gray-900">{GetLocalLangValue("GW")}: {product?.gw}</p>
                  </div>
                  <div className="mt-8">
                    <p className="font-medium text-gray-900">{GetLocalLangValue("Cuft")}:  {product?.cuft}</p>
                  </div>                       
            </section>
          </div>
                        
      
    </>
  )
}