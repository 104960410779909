import {
    getTokenFromLocalStorage
} from "../api/client";


export const hasValidToken = () => {
    
  
    const storageToken = getTokenFromLocalStorage();  
    console.log("token");
    console.log(storageToken?.token);
    if (storageToken) {
       // if(hasTokenExpired(storageToken.token))
       if(storageToken.token)
            return true;
        else
            return false;
    }
    return false;
};