import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { storeProfileStatusInLocalStorage } from '../api/client';
import { ProfileStatusType } from '../contracts/enums/profile-status';
import { PatchProfileRequest } from '../contracts/request-models/patch-profile';
import { Profile } from '../contracts/response-models/profile';
import { getUserProfileService, patchUserProfileService } from '../machines/admin/services';


export default function ProfileForm() {

    const [companyName,setCompanyName] = useState('');
    const [companyProvince,setCompanyProvince] = useState('');
    const [companyCity,setCompanyCity] = useState('');
    const [businessNature,setBusinessNature] = useState('');
    const [companyAddress,setCompanyAddress] = useState('');
    const [name,setName] = useState('');
    const [gender,setGender] = useState('');
    const [age,setAge] = useState('');
    const [cell,setCell] = useState('');
    const [userId,setUserId]=useState(0);
    
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const history=useHistory();
    const [isLoading,setIsLoading] = useState(false);

    const BusinessTypes = ['Retail','Wholesale','Designer']
    
    useEffect(() => {
        
        async function loadProfileInfo() {                  
            setIsLoading(true);

            const result = await getUserProfileService();    
            setName(result.data[0].name);
            setGender(result.data[0].gender);   
            setAge(result.data[0].age);                                           
            setCell(result.data[0].mobile_no);   
            setCompanyAddress(result.data[0].address);   
            setCompanyCity(result.data[0].city);
            setCompanyProvince(result.data[0].province);  
            setCompanyName(result.data[0].company_name);  
            setBusinessNature(result.data[0].business_type);   

            setIsLoading(false);
        }
    
        loadProfileInfo();
        
      }, []);

    
    
    const saveProfile = async ()=>{
        setLoading(true);
        setError('');
        
        let request:PatchProfileRequest= {
            company_name:companyName,
            province:companyProvince,
            city:companyCity,
            business_type:businessNature,
            address:companyAddress,
            name:name,
            gender:gender,
            age:age   
            };

        try {
            
          const result = await patchUserProfileService(userId,request);
          saveProfoleStatusInLocalStorage();
          setError(result.message); 
          history.push('/');

        } catch (e:any) {
          
            setError(e.message);
        
        }
             
        setLoading(false);
        
     }
     const navigation = [
        { name: 'Account', href: '', icon: UserCircleIcon, current: true },
        // { name: 'Password', href: '#', icon: KeyIcon, current: false },
        // { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: false },
        // { name: 'Team', href: '#', icon: UserGroupIcon, current: false },
        // { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
      ]
      function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
      }

     const saveProfoleStatusInLocalStorage=()=>{

        const partialRequiredFields = [name,companyName,companyProvince,companyCity];
        const completeRequiredFields = [name,companyName,companyProvince,companyCity,companyAddress,businessNature,gender,age];

        if(partialRequiredFields.filter((field)=>field==null || field.length==0).length>0){
            storeProfileStatusInLocalStorage(ProfileStatusType.None);
            return;
        }
        
        storeProfileStatusInLocalStorage(ProfileStatusType.Partial);

        if(completeRequiredFields.filter((field)=>field==null || field.length==0).length>0){            
            return;
        }


        storeProfileStatusInLocalStorage(ProfileStatusType.Complete);

        /*if (name && name.length>0 && 
            companyProvince && companyProvince.length>0 && 
            companyCity && companyCity.length>0 && 
            companyName && companyName.length>0 && 
            companyAddress && companyAddress.length>0 && 
            businessNature && businessNature.length>0 && 
            gender && gender.length>0 && 
            age && age.length>0 
        )
        {
            storeProfileStatusInLocalStorage(ProfileStatusType.Complete);
            return;
        }
        
        if (name && name.length>0 && 
            companyProvince && companyProvince.length>0 && 
            companyCity && companyCity.length>0 && 
            companyName && companyName.length>0)
        {
            storeProfileStatusInLocalStorage(ProfileStatusType.Partial);
        }*/
        
       
     }
    return(
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-8 ">
        <div className="lg:grid lg:grid-cols-12   lg:gap-x-5">
            <aside className=" px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                            item.current
                            ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 '
                            : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                            'group rounded-md px-3 py-2 flex items-center text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                        >
                        <item.icon
                            className={classNames(
                            item.current
                                ? 'text-indigo-500 group-hover:text-indigo-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                        </a>
                    ))}
                </nav>
            </aside>
            {isLoading && (
                        <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-indigo " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>           
                    )}

            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 ">
            {!isLoading && (
                <div className="shadow sm:rounded-md sm:overflow-hidden bg-gray-50">
                   
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 sm:ml-8 ml-8 ">
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="Name" className="block text-base font-medium text-gray-700">
                                            Name:
                                            </label>
                                            <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                <input
                                                type="text"
                                                value={name}
                                                onChange={(e)=>{
                                                    setName(e.target.value);}}
                                                className="max-w-lg block w-full pl-2 h-10 sm:h-10 sm:text-base shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border sm:max-w-xs  border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="about" className="block text-base font-medium text-gray-700 sm:mt-px  sm:pt-2">
                                                Gender
                                            </label>
                                            <div className="sm:col-span-2">
                                                <div className="mt-2 sm:mt-2 inline-flex">
                                                <input
                                                    name="Gender"
                                                    type="radio"
                                                    checked={gender=="Male"}
                                                    onChange={()=>setGender('Male')}
                                                    className="focus:ring-indigo-500 pl-2 h-8 sm:h-8 sm:text-base  w-4 text-indigo-600 border border-gray-300"
                                                />
                                                <label htmlFor="Gender" className="ml-3 block text-base font-medium  text-gray-700">
                                                    Male
                                                </label>
                                                </div>
                                            
                                                <div className="mt-2 sm:mt-2 sm:ml-4 ml-4  pt-4 sm:pt-0 inline-flex">
                                                    <input
                                                            name="Gender"
                                                            type="radio"
                                                            checked={gender=="Female"}
                                                            onChange={()=>setGender('Female')}
                                                            className="focus:ring-indigo-500 h-8 sm:h-8 sm:text-base pl-2 w-4 text-indigo-600 border-gray-300"
                                                    />
                                                    <label htmlFor="push-email" className="ml-3 block text-base font-medium text-gray-700">
                                                        Female
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="Age" className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Age:

                                            </label>
                                            <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                <input
                                                type="text"
                                                value={age}
                                                onChange={(e)=>{
                                                    setAge(e.target.value);}}
                                                className="max-w-lg block w-full pl-2 h-10 sm:h-10 sm:text-base shadow-sm focus:ring-indigo-400 focus:border-indigo-500 border sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="Age" className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Cell:

                                            </label>
                                            <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                <input
                                                type="text"
                                                value={cell}
                                                readOnly
                                                onChange={(e)=>{
                                                    setCell(e.target.value);}}
                                                className="max-w-lg block w-full pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-400 focus:border-indigo-500 border sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="companyname" className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Company Name:
                                            </label>
                                            <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                <input
                                                type="text"
                                                value={companyName}
                                                onChange={(e)=>{
                                                    setCompanyName(e.target.value);}}
                                                className="max-w-lg block w-full pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="Companylocation" className="block text-base font-medium text-gray-700 sm:mt-px  sm:pt-2">
                                                Company location
                                            </label>
                                            <div className="sm:col-span-2 ">

                                                <div className="mt-2 sm:mt-2 inline-flex ">
                                                    <input
                                                    type="text"
                                                    placeholder="Province"
                                                    value={companyProvince}
                                                    onChange={(e)=>{
                                                        setCompanyProvince(e.target.value);}}
                                                    className="max-w-lg block w-full sm:w-32 pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                    />
                                                </div>
                                                
                                                <div className="mt-2 sm:mt-0  pt-4 sm:pt-0 inline-flex ml-0 sm:ml-8 ">
                                                    <input
                                                    type="text"
                                                    placeholder="City"
                                                    value={companyCity}
                                                    onChange={(e)=>{
                                                        setCompanyCity(e.target.value);}}
                                                    className="max-w-lg block w-full sm:w-35 pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="photo" className="block text-base font-medium text-gray-700">
                                                Nature of business: 
                                            </label>
                                            <div className="sm:col-span-2 inline-flex">
                                                <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                    <select
                                                    
                                                    onChange={(e)=>{setBusinessNature(e.target.value);
                                                        // if(e.target.value!="Other" ){
                                                        //     setBusinessNature(e.target.value);
                                                        //     setVisibility('hidden mt-1 sm:mt-2 sm:col-span-2  ');
                                                        // }                                                  
                                                        // else{
                                                        //     if(businessNature=='Retail' || businessNature=='Wholesale' || businessNature=='Designer')
                                                        //     {
                                                        //         setBusinessNature('');
                                                        //     }
                                                        //     setVisibility('visible mt-1 sm:mt-2 sm:col-span-2 ml-8');
                                                        //     setValue("Other");
                                                        // }
                                                        
                                                        }
                                                    }
                                                    className="max-w-lg block w-full sm:w-32 pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                    >
                                                    <option></option>
                                                     
                                                    {BusinessTypes.map((value,index)=>{
                                                       
                                                    if(businessNature==value){
                                                        return (<option selected>{value}</option>);}
                                                   
                                                    return (<option>{value}</option>)
                                                    })} 
                                                    </select>
                                                </div>
                                                {/* <div className={visibility}>
                                                    <input
                                                    type="text"
                                                    value={businessNature}
                                                    placeholder="Other"
                                                    onChange={(e)=>{ 
                                                    if(businessNature=='Retail' || businessNature=='Wholesale' || businessNature=='Designer')
                                                        {
                                                        setVisibility('hidden mt-1 sm:mt-2 sm:col-span-2  ');}
                                                    setBusinessNature(e.target.value);}}
                                                    className="max-w-lg block w-full pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                    />
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="cover-photo" className="block text-base font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Company Full Adress:
                                            </label>
                                            <div className="mt-2 sm:mt-2 sm:col-span-2">
                                                <input
                                                    type="text"
                                                    value={companyAddress}
                                                    onChange={(e)=>{
                                                        setCompanyAddress(e.target.value);}}
                                                    className="max-w-lg block w-full pl-2 h-10 sm:h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                                />
                                            </div>
                                        </div>
                                    </div>        


                    <div className="px-4 py-3 bg-white text-right sm:px-6 border sm:border-gray-200 mt-6 sm:mt-6">
                        <div className="py-2">            
                            {error && (
                            <span>{error}</span>
                            )}  
                        </div>                               
                        <button 
                            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            type="button"
                            onClick={saveProfile}>
                            {isLoading && (
                            <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                            )} 
                        Save    
                        </button>
                    </div>
                </div>
                            )}

                

                
            </div>
        </div>
    </div>
  )
}