import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkCart } from "../../utils/check-cart-products";
import { hasValidToken } from "../../utils/has-valid-token";
import { GetLocalLangValue } from "../../utils/language";
import LoginModal from "../LoginModal";
import QuantityModal from "../ShoppingCart/QuantityModal";

interface ProductBoxParam{
  product:any;
  currentView:any;
  setView:any;
  language:any;
  priceType:any;
}


export default function ProductBox({currentView,product,setView,language,priceType}:ProductBoxParam) {
    const [loginModal,setLoginModalOpen] = useState(false);
    const [quantityModal,setQuantityModalOpen]=useState(false);
    const [viewType,setViewType]=useState(false);

    if(quantityModal)
      return (
        <QuantityModal product={product} open={quantityModal} setOpen={setQuantityModalOpen} view={viewType}/>
      )

    if(loginModal)
      return (
        <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
      )

  return (
    <div
        key={product.id}
        className="grid grid-cols-2 gap-2 relative bg-white border border-gray-200  rounded-lg flex flex-col overflow-hidden"
      >
        <div className="w-36 aspect-w-1 aspect-h-1 item-center group-hover:opacity-75  cursor-pointer">
          <Link to={`/products?id=${product.id}`}>
            <img
                      src={"https://api.lihongart.com/storage/uploads/thumbnail/"+product?.image?.[0]}
                      //className="w-full h-full object-center object-cover sm:rounded-lg"
                      className="w-30 h-22 pl-6 rounded-md item-center object-cover sm:2-full sm:h-44"
            />
          </Link>
        </div>
        <button
                type="button"
                className="absolute left-2 bg-gray-600 bottom-1 h-6 w-30 px-2 border border-transparent rounded-md text-sm text-white hover:text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                onClick={()=>{
                setView(!currentView);
              }}
              >{GetLocalLangValue("FullPhotoView")}
          </button>
        
        <div className="flex-1 p-4 space-y-2 flex flex-col bg-gray-100">
          
            <h3 className="text-sm font-medium text-gray-900">                                       
              {product.title}
            </h3>
             
            
         
          
          { language=='Chinese'  ?( 
          <p className="text-sm text-gray-500">{product.description}</p> ):
          (<p className="text-sm text-gray-500">{product.description_english}</p>)                
          }
           {!hasValidToken() ? ( 
               <p className="text-sm text-gray-500">
                <h2 className="text-xl font-medium text-gray-900 sm:pr-12" role="button" onClick={()=>(setLoginModalOpen(!loginModal))}>{GetLocalLangValue("SeePrice")}</h2>                          
               </p>                         
               
                ) : (
                <> {priceType=='Yuan' ? (
                  <h3 className="text-sm font-medium text-gray-900">    ¥ {product?.price}</h3>):(
                    <h3 className="text-sm font-medium text-gray-900">    $ {product?.usd_price}</h3>  
                  )}                         
                  {<button
                    type="button"
                    className="bg-indigo-600 h-6 w-30 border border-transparent rounded-md px-2 text-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 max-w-max"
                    onClick={async ()=>{
                      setViewType(await checkCart(product.id));
                      setQuantityModalOpen(!quantityModal);
                      
                    }}
                  >{GetLocalLangValue("ToCart")}
                  </button>}
                </>
                )}
            
          
         
        </div>
      </div>
    
  )
}