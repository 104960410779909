import {client} from "../client";
import { ActionResponse } from "../../contracts/response-models/action-response";
import { Orders } from "../../contracts/response-models/order";
 
function getOrderSummary(){ 
    return client<ActionResponse<Orders>>(`/getuserorders`);
}

export { getOrderSummary }

