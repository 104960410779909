import { Eye } from '@emotion-icons/heroicons-outline';
import { EyeIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Orders } from "../contracts/response-models/order"; 
interface OrderProps{
    orderInfo: Orders; 
    currencyType:any;
  }

function OrderRow({orderInfo,currencyType}:OrderProps) { 
 let date= (orderInfo.created_at).toString().split('T')[0]
  date=date.split("-").reverse().join("-"); 
  return (
    <tr>
     
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
      <Link to={`/orders?id=${orderInfo.id}`} >{orderInfo.order_id} </Link> 
      </td> 
      {currencyType=='Yuan'?(
      <td className="px-8 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
        ¥ {orderInfo.order_total}
      </td>):(
        <td className="px-8 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
        $ {orderInfo.order_total_usd}
      </td>
      )
      }
      <td className="px-8 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
      {date}
      </td>
         
      
      <td className="px-2 py-4 whitespace-nowrap text-right  text-sm font-medium">
        {/* <Link to={`/orderdetail`} className="text-indigo-600 hover:text-indigo-900">  */}
        <Link  to={`/orders?id=${orderInfo.id}`} className="text-indigo-600 hover:text-indigo-900">
        <EyeIcon className="h-6 w-6" aria-hidden="true"/>
       </Link>
      </td>
    </tr>
  );
}

export default OrderRow;
