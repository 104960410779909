import { AddToCartRequest } from "../contracts/request-models/add-to-cart";
import { checkCartItemService } from "../machines/admin/services";

export const checkCart = async (id:any)=>{
    
    let request:AddToCartRequest={
      product_id:id
    }
    var view:boolean=(false);
    try{
      const result = await checkCartItemService(request);           
      view=(result.data);
    } 
    catch (e:any) {
        
    }
    
    return view;
    
}