import {client} from "../client";
import { ProductFront } from "../../contracts/response-models/productfront";

 
function homePageList(paged:number,perpagearecord:number,sortedColumn:string,sortDirection:string,searchkey:string,categories?:string){
    //https://api.lihongart.com/api/productsList/listing/12/point/desc?page=1
    //return client<PagedResult<Product>>(`/products/listing/${perpagearecord}/${sortedColumn}/${sortDirection}?page=${paged}`);   
    var catParam = categories!=undefined ? categories : '';
    return client<ProductFront[]>(`/productsList/listing/${perpagearecord}/${sortedColumn}/${sortDirection}?page=${paged}&searchkey=${searchkey}&categoryid=${catParam}`);   
}

export { homePageList }