import React from "react";
import { useEffect, useState } from "react";
import { getSeriesService, listProductsForHomePage} from "../../machines/admin/services";
import { Fragment } from 'react';
import { Dialog,Transition } from '@headlessui/react';
import { ProductFront } from "../../contracts/response-models/productfront";
import Productloading from "./Productloading";
import LoginModal from "../LoginModal";
import ProductView from "./ProductView";
import { FilterOption } from "../../contracts/local/filter-option";
import { GetLocalLangValue } from "../../utils/language";


export default function ProductGrid() {

    const [loading, setLoading] = useState(false); 
    const [loginModal,setLoginModalOpen] = useState(false); 
    const [selectedCategories,setSelectedCategories] = useState<string[]>([]);

    const [products, setProducts] = React.useState<ProductFront[]>([]);
    // const [productForQuickView, setProductForQuickView] = React.useState<ProductFront>();

    const [readyState, setReadyState] = useState(true);
    const [paged, setPaged] = useState(1);
    const [perpagearecord, setPagePerRecord] = useState(15);
    const [sortedColumn, setSortedColumn] = useState('point');
    const [sortDirection, setSortDirection] = useState('desc');
    const [searchkey, setSearchkey] = useState('');
    const [productChanged,setProductChanged] = useState(false);
    const [refreshResult, setRefreshResult] = useState(true);
    const [hasMoreRecords, sethasMoreRecords] = useState(false);
    const [filters,setFilters] = useState<FilterOption[]>([]);

    useEffect(() => {
      async function loadSeries() {                  
          const result = await getSeriesService();  
          const catOptions = result.map((series)=>{
           return {value:series.id.toString(),label:series.title,checked:false};
          })  
          const catFilter = {
            id:'category',
            name:'Series',
            options:catOptions
          };    
          setFilters(filters => [...filters, catFilter]);
  
      
      }
      loadSeries();
      
    }, []);
    

    useEffect(() => {
      async function loadProoducts() {
        
        if (readyState == true) {
          setLoading(true);
          var cats = selectedCategories?.join(",");
          const newproducts = await listProductsForHomePage(paged, perpagearecord, sortedColumn, sortDirection,searchkey,cats);   
          sethasMoreRecords(newproducts.length==perpagearecord);
          if(refreshResult){
            setProducts(newproducts);
          }
          else{
            setProducts(products.concat(newproducts));
          }
          
          setLoading(false);
         // setReadyState(false); 
        }
      }
  
      loadProoducts();
  
    }, [readyState, paged, perpagearecord, sortedColumn, sortDirection,selectedCategories,productChanged]);
    
    const [open,setOpen] = useState(false);
    
    
    const dummyList = [1,2,3,4,5,6];
    if(open)
      return (
        <Transition.Root show={open} as={Fragment}>
        
          <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-6xl">
                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    
                  >
                    {/* <span className="sr-only">Close</span> */}
                    {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                  </button>
                 
                
                </div>
              </div>
            </Transition.Child>
          </div>
        
      </Transition.Root>
    )

    
    if(loginModal)
        return (
          <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
        )
      
    return (
      <div className="bg-white">
        <div className="max-w-2xl py-8 mx-auto px-4 sm:py-8  sm:px-6 lg:max-w-7xl lg:px-8">
           

          <div className="flex justify-between items-center mb-7">
            <div>
            <h1 className="text-2xl font-bold text-heading hidden lg:inline-flex pb-1">{GetLocalLangValue("Products")}</h1>
            </div>            
            <div className="justify-self-end">
              <input type="text" placeholder={GetLocalLangValue("Search")}  className=" border pl-2 mr-4 w-90 h-10 rounded-md 	border-gray-300  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  hover:border-gray-500 "  onChange={(event)=>{
                setLoading(true);
                setReadyState(true);
                setRefreshResult(true);
                setPaged(1);
                setSearchkey(event.target.value);
                setProductChanged(!productChanged);
              }} />          
                
            
               <select onChange={(e)=>{
                 setLoading(true);
                  setReadyState(true);
                  setRefreshResult(true);
                  setPaged(1);
                  setSortedColumn(e.target.value);
                  setProductChanged(!productChanged);
                }}
                className="mt-1 mr-4  pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md hover:border-gray-500"
                >
                <option value="point">{GetLocalLangValue("Default")}</option>
                <option value="title">{GetLocalLangValue("ItemNo")}</option>
                <option value="nw">{GetLocalLangValue("NW")}</option>
                <option value="height">{GetLocalLangValue("Height")}</option>
                <option value="cuft">{GetLocalLangValue("Cuft")}</option>
                <option value="price">{GetLocalLangValue("Price")}</option>
              </select>
              { filters.map((section)=>( 
                <select
                onChange={(e)=>{
                  selectedCategories.pop();
                  setLoading(true);
                  setReadyState(true);
                  setRefreshResult(true);
                  setPaged(1);
                  setProductChanged(!productChanged);
                  selectedCategories.push(e.target.value);
                  setProductChanged(!productChanged);
                 }}
                className="mt-1  pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md hover:border-gray-500"
                placeholder="Series"
                >
                <option value="">{GetLocalLangValue("AllSeries")}</option>
                {section.options.map((option, optionIdx)=>{
                  return (<option value={option.value} id={`filter-${section.id}-${optionIdx}`}
                           >{GetLocalLangValue("" + option.label)}</option>)
                })}               
                </select>
              ))}  
            </div>
           
          </div>

          
          
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 mb-8">
          {loading &&  
            dummyList.map((item, index)=>(<Productloading />))
          } 
            <ProductView products={products}/>
          </div> 
        
          {hasMoreRecords && (
            <div className="text-center">
              <button onClick={()=>{
                setReadyState(true);
                setRefreshResult(false);
                setPaged(paged+1);}}
                className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                >
                  {loading && (
                    <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                  )}                         
                  {GetLocalLangValue("LoadMore")}
              </button>
            </div>
          )}
          
        </div>
      </div>
    )
  }


