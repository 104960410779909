export interface LanguageItem{
    Chinese: string;
    English: string;
}

var languageDictionary:{[id: string]: LanguageItem;} ={
    "Products": { Chinese: "产品", English: "Products" } ,
    "FullPhotoView": { Chinese: "照片放大", English: "Full Photo View" },
    "ListView": { Chinese: "照片缩小", English: "List View"  },
    "OrderArchives": { Chinese: "订单记录", English: "Order Archive" } ,
    "Account": { Chinese: "帐户", English: "Account" } ,
    "Search": { Chinese: "搜寻", English: "Search.." } ,
    "Default": { Chinese: "预设顺序", English: "Default" } ,
    "Size": { Chinese: "尺寸", English: "Size" } ,
    "LoadMore": { Chinese: "装载更多", English: "LoadMore.." } ,   
    "ItemNo": { Chinese: "产品编号 (依产品编号排序)", English: "Item No." } ,
    "NW": { Chinese: "净重(依净重排序)", English: "N.W" },
    "GW": { Chinese: "总重量", English: "G.W" }  ,
    "Height": { Chinese: "高度(依高度排序)", English: "Height" } ,
    "Cuft": { Chinese: "材数(依材数排序)", English: "Cuft" } ,
    "Price": { Chinese: "价格(依价格排序)", English: "Price" } ,
    "AllSeries": { Chinese: "全系列", English: "All Series" } ,
    "A": { Chinese: "只显示 A 系列", English: "A" } ,
    "B": { Chinese: "只显示 B 系列", English: "B" } ,
    "C": { Chinese: "只显示 C 系列", English: "C" } ,
    "D": { Chinese: "只显示 D 系列", English: "D" } ,
    "E": { Chinese: "只显示 E 系列", English: "E" } ,
    "F": { Chinese: "只显示 F 系列", English: "F" } ,
    "G": { Chinese: "只显示 G 系列", English: "G" } ,
    "H": { Chinese: "只显示 H 系列", English: "H" } ,
    "SignIn": { Chinese: "登入", English: "Sign In" } ,
    "SignOut": { Chinese: "登出", English: "Sign Out" } ,
    "Profile": { Chinese: "详细资料", English: "Profile" } ,
    "SeePrice": { Chinese: "登入后价格会显示", English: "See Price" } ,
    "EnterYourCell": { Chinese: "请填入手机号", English: "Enter your cell" } ,
    "EnterYourOTP": { Chinese: "输入收到的代码", English: "Enter the code received" } ,
    "EnterTheQuantity": { Chinese: "输入数量", English: "Enter the quantity" } ,
    "AlreadyInCart": { Chinese: "该产品已在购物车中", English: "This product is already in cart." } ,
    "SendOTP": { Chinese: "发验证码", English: "Send OTP" },
    "Verify": { Chinese: "核实", English: "Verify" }  ,
    "ToCart": { Chinese: "加入购物车", English: "To Cart" } ,
    "ShoppingCart": { Chinese: "购物车", English: "Shopping Cart" },
    "ContinueShopping": { Chinese: "继续购物", English: "Continue Shopping" } ,
    "OrderSummary": { Chinese: "订单摘要", English: "Order Summary" } ,
    "OrderDate": { Chinese: " 订购日期", English: "Order date" } ,
    "MyOrders": { Chinese: " 我的订单", English: "My Orders" } ,
    "OrderId": { Chinese: " 订购日期", English: "Order date" } ,
    "Quantity": { Chinese: "数量", English: "Quantity" } ,
    "Subtotal": { Chinese: "小计", English: "Subtotal" } ,
    "ShippingEstimate": { Chinese: "运费预估", English: "Shipping Estimate" } ,
    "TaxEstimate": { Chinese: "税金预估", English: "Tax Estimate" } ,
    "OrderTotal": { Chinese: "总价", English: "Order total" } ,
    "Checkout": { Chinese: "创建订单摘要", English: "Create order summary" } , 
    "Memo": { Chinese: "备忘录", English: "Memo" } , 
    "EmptyCart": { Chinese: " 购物车是空的", English: "Cart is empty" } ,  
}

export const GetLocalLangValue=(label:any)=>{
    const lan=window.localStorage.getItem('__language__');
    if(lan==='Chinese')
    {
        return languageDictionary[label].Chinese;
    }
    return languageDictionary[label].English;
}
