import {  ShoppingCartIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { getCartItemsService } from "../../machines/admin/services";

export default function CartSummary(){
    const [itemCount,setItemCount]=useState(0);
    
    
return(
   
    <div className="ml-4 px-4 flow-root lg:ml-6">
    <a href="/cart" className="group -m-2 p-2 px-2 flex items-center">
            <ShoppingCartIcon
            className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"/>
            <span className="ml-2 hidden text-sm font-medium text-gray-700 group-hover:text-gray-800">{itemCount}</span>
            <span className="sr-only">items in cart, view bag</span>
        </a>
    </div>
)    
}