import { useEffect } from "react";
import { useState } from "react";
import LoginModal from "../components/LoginModal";
import Sign_in_form from "../components/Sign_in_form";

export default function SignInPage () {
    const [loginModal,setLoginModalOpen] = useState(true);

   

     
    return(
        <>
        <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
        
       </>
    )
}