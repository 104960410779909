import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getProductInfo } from '../api/products/get';
import ProductDetails from '../components/Product/ProductDetails';
import { ProductFront } from '../contracts/response-models/productfront';

export default function ProductDetailsPage () {
    let productId; 
    const [product,setProduct] = useState<ProductFront>();
    const [language,setLanguage]=useState<any>();
    const [priceType,setPriceType]=useState<any>();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
      let query = useQuery();
      if(query.get("id")){
        let idParam = query.get("id");
        productId = idParam;
        
      }
    
    const [id, setId] = useState<any>(productId);
  
  useEffect(() => {    
    
    async function loadProfileInfo() {             
          
        const result = await getProductInfo(id);    
        const lan= window.localStorage.getItem('__language__');
        const price= window.localStorage.getItem('__currency_type__');
        setPriceType(price);
        setLanguage(lan);
        setProduct(result);
  }

  loadProfileInfo();
 
  
},[]);

    

     return(
        
        <div className="bg-white">
            <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                    
                    <ProductDetails product={product} language={language} priceType={priceType}/>
                            
                </div>
            </div>    
        </div>
     )
 }