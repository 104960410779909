import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import LoginModal from "../components/LoginModal";
import { hasValidToken } from "../utils/has-valid-token";

interface IProps {
  path?:any,
  exact?:any,
  component: any,  
  // any other props that come into the component
}

export default function PrivateRoute({
  component: Component,  
  ...props
}:IProps) {
  const [loginModal,setLoginModalOpen] = useState(true); 
 
      
    const signed = hasValidToken();
 
// const signed = false;
  if (signed===false) {
    
    return (
      <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
    )
  }
  
  
  return (
    <Route
      {...props}
      render={props => (        
        <Component {...props} />        
      )}
    />
  );
}

