import ShoppingCart from "../components/ShoppingCart/ShoppingCart";

export default function ShoppingcartPage() {
     
    return(
        

                    <ShoppingCart />

               
    )
}  