/*export  const roundUp= (quantity:any)=>{

    if((quantity%5)==0)
      return quantity
    else
    {
      var newQty = Math.floor(parseInt(quantity)/5);
      return ((newQty)+1)*5;
    }

  }*/

  export  const roundUp= (price:any)=>{

    if(price>=500)
      return 1;
          
    var newQty = Math.floor(500/parseInt(price));         
   
    if((newQty%5)===0)
      return newQty;
  
    newQty = Math.floor(parseInt(newQty.toString())/5);
    newQty = ((newQty)+1)*5;      

    console.log(newQty);
    return newQty;
  
  }