
import {client} from "../client";
import { Profile } from "../../contracts/response-models/profile";
import { ActionResponse } from "../../contracts/response-models/action-response";
 
function getProfile(){ 
    return client<ActionResponse<Profile>>(`/get-profile`);
}

export { getProfile }

