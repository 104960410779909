import ProductGrid from '../components/Product/ProductGrid';



 export default function HomePage () {
     
     return(
        
         <ProductGrid/>
        
     )
 }