import { useEffect, useState } from 'react'
import {  QuestionMarkCircleIcon} from '@heroicons/react/solid'
import { CartItem } from '../contracts/response-models/cart';
import {  getOrderService } from '../machines/admin/services';
import { currencyType } from '../utils/currency-type';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from './BreadCrumb';
import { GetLocalLangValue } from '../utils/language';

export default function OrderDetails() {

  const [orderItems,setOrderItems]=useState<CartItem[]>([]);
  const priceType=window.localStorage.getItem('__currency_type__');
  const [memo,setMemo]=useState('');
  const [title,setTitle]=useState('');
  const [total,setTotal]=useState<number>(0);
  const [subTotal,setSubTotal]=useState<number>(0);
  const [shippingEstimate,setShippingEstimate]=useState<number>(0.00);
  const [taxEstimate,setTaxEstimatel]=useState<number>(0.00);
  
  let orderId;
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  if(query.get("id")){
    let idParam = query.get("id");
    orderId = idParam;
  }
  
  const [id, setId] = useState<any>(orderId);

    useEffect(() => {    
      async function getOrder() {
        
          if(id){
              const result = await getOrderService(id);             
               setOrderItems(result.order_items);
               setTitle((result.order_id).toString());
               setMemo(result.memo);
              var calculatedSubTotal
              if(priceType=='Yuan')
              { 
                  calculatedSubTotal =  result.order_total;
                  calculatedSubTotal= Number.parseInt(calculatedSubTotal.toString());
              }
              else{
                  calculatedSubTotal = result.order_total_usd;    
                 
              }
              setSubTotal(calculatedSubTotal);
              setTotal(calculatedSubTotal+(shippingEstimate+taxEstimate));
          }
         
        
      }
      
      getOrder();
      
  },[id]);
       
      
   
  

  return (
    <div className="bg-white">
       
        <main className="max-w-2xl mx-auto pt-6 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <Breadcrumb mainTitle={GetLocalLangValue("MyOrders")} mainLink="/order-summary" childTitle={title} childLink="" />
            <div className="mt-8 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
              
                <section aria-labelledby="cart-heading" className="lg:col-span-12">
                 
                    <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                    </h2>
                    
                    <ul role="list" className="border-t border-b border-gray-200 divide-y divide-gray-200">
                          
                    {orderItems && orderItems.map((items)=>
                    <li key={items.product_id} className="flex py-6 sm:py-10">
                    <div className="flex-shrink-0">
                        <Link to={`/products?id=${items.product_id}`}>
                            <img
                            src={"https://api.lihongart.com/storage/uploads/thumbnail/"+items.sku_id+".png"}
                            className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                            />
                        </Link>    
                    </div>

                    <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 pl-0 sm:pl-20">
                        <div className="pl-0 sm:pl-32">
                            <div className="flex justify-between">
                                <h3 className="text-sm">
                                     <a href={"/products?id="+items.product_id} className="text-lg font-bold text-gray-500 hover:text-gray-900">
                                        {items.title}
                                    </a> 
                                </h3>
                            </div>
                            {priceType=='Yuan' ? (<>
                            <p className="sm:mt-2 mt-1 text-md font-medium text-gray-900">¥ {items.price}</p>
                            
                            <p className="sm:mt-2 mt-1 text-md font-medium text-gray-900">{GetLocalLangValue("Subtotal")} :  ¥ {items.price*items.quantity}</p></>):(<>
                            <p className="sm:mt-2 mt-1 text-md font-medium text-gray-900">$ {items.usd_price}</p>
                            
                            <p className="sm:mt-2 mt-1 text-md font-medium text-gray-900">{GetLocalLangValue("Subtotal")} :  $ {items.usd_price*items.quantity}</p>
                            </>)}
                        </div>

                        <div className="mt-4 sm:mt-0 sm:pr-9">
                            <label className="pr-4">
                            {GetLocalLangValue("Quantity")} : 
                            </label>
                            <label className="pr-4">
                              {items.quantity}
                            </label>
                        </div> 
                        
                        </div>
                    </div>
                   
                    </li>
                   )}
                
            </ul>

                </section>

                {/* Order summary */}
                <section
                    aria-labelledby="summary-heading"
                    className="mt-16 bg-white rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-12"
                >
                    <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                    {GetLocalLangValue("OrderSummary")}
                    </h2>
                   <div className='inline-flex w-full '> 
                    <dl className="mt-6 space-y-4 p-2 md:pl-6 rounded-md  w-1/2 bg-gray-100">
                        <div className="flex items-center justify-between">
                            <div>
                            <dt className="text-sm text-gray-600 mr-4">{GetLocalLangValue("Subtotal")}</dt>
                            </div>
                            <div>
                            <dd className="text-sm font-medium text-gray-900">{currencyType(subTotal)}</dd>
                            </div>
                        </div>
                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                            <dt className="flex items-center text-sm text-gray-600">
                            <span>{GetLocalLangValue("ShippingEstimate")}</span>
                            <a href="" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Learn more about how shipping is calculated</span>
                                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">{currencyType(shippingEstimate)}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                            <dt className="flex text-sm text-gray-600">
                            <span>{GetLocalLangValue("TaxEstimate")}</span>
                            <a href="" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Learn more about how tax is calculated</span>
                                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                            </dt>
                            <dd className="text-sm font-medium text-gray-900">{currencyType(taxEstimate)}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                            <dt className="text-base font-medium text-gray-900">{GetLocalLangValue("OrderTotal")}</dt>
                            <dd className="text-base font-medium text-gray-900">{currencyType(total)}</dd>
                        </div>
                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        
                        </div>
                    </dl>
                    <dl className="mt-6 pl-0 pr-0 md:pl-6 w-1/2">
                        <label className='text-gray-600'>{GetLocalLangValue("Memo")}: </label>
                        <br/>
                        <label className='p-2'>{memo}</label>
                        
                    </dl>  
                  </div>  
                </section>
            </div>
            
        </main>

     
    </div>
  )
}
