import { Component } from 'react';
import Header from './components/Header';
import {BrowserRouter as Router } from "react-router-dom";
import Routes from './routes/Routes';
import Footer from './components/Footer';


class App extends Component{
  render() {
  return (
    <Router>
        <div className="App">
          <Header />         
        </div>
        <Routes/>
        <Footer/>
    </Router>
  );
}
}
export default App;
