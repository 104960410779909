import { useEffect, useState } from "react";
import { ProfileStatusType } from "../contracts/enums/profile-status";
import { CartItem } from "../contracts/response-models/cart";
import { getCartItemsService } from "../machines/admin/services";
import { trimLeadingSlash, trimTrailingSlash } from "../utils";

export const localStorageKeyToken = "__lihongart_website_token__";
export const localStorageKeyProfileStatus = "__lihongart_profile_status__";
export const localStorageKeyBaseUri = "__lihongart_uri__";
export const localStorageIsAdmin = "__lihongart_admin__";
export const localStorageProducts = "__cart_products__";

interface Headers {
    [x: string]: string;
}

export const client = async <TResponse>(
    endpoint: string,
    body?: any,
    customConfig?: RequestInit
    ): Promise<TResponse> => {
    const baseUri = 'https://api.lihongart.com/api'//window.localStorage.getItem(localStorageKeyBaseUri);
    const token = window.localStorage.getItem(localStorageKeyToken);

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    // await sleep(2000);

    return consume(baseUri, endpoint, token, body, customConfig);
    };

    export const consume = <TResponse>(
    baseUri: string,
    endpoint: string,
    token?: any,
    body?: any,
    customConfig?: RequestInit
    ): Promise<TResponse> => {
    const headers: Headers = { "content-type": "application/json","Content-Type":"multipart/form-data" };

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const config: RequestInit = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
        ...headers,
        ...customConfig?.headers,
        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }
    return window.fetch(uri(baseUri, endpoint), config).then(async (response) => {
        let data;

        try {
        data = await response.json();
        } catch {
        // TODO: Change API client logic
        data = null;
        }
        
        if (response.ok) {
        return data as TResponse;
        } else {
            if(response.status==409)
            {  
              return response as unknown as TResponse;
            }else {
        return data
            ? Promise.reject<TResponse>(data)
            : Promise.reject<TResponse>(
                response.statusText || `Unexpected error code ${response.status}`
            );
            }

        }
    });
    };

    export function getTokenFromLocalStorage() {
    const baseUri = window.localStorage.getItem(localStorageKeyBaseUri);
    const token = window.localStorage.getItem(localStorageKeyToken);
    const isAdmin =     window.localStorage.getItem(localStorageIsAdmin);
    const profileStatus= window.localStorage.getItem(localStorageKeyProfileStatus);
    if (!baseUri || !token) {
        return null;
    }

    return { baseUri, token,isAdmin,profileStatus };

}

  
export function storeTokenInLocalStorage(baseUri: string, token: string,isAdmin:Boolean,profile_status:ProfileStatusType) {
    window.localStorage.setItem(localStorageKeyBaseUri, baseUri);
    window.localStorage.setItem(localStorageKeyToken, token);
    window.localStorage.setItem(localStorageIsAdmin,isAdmin.toString());
    window.localStorage.setItem(localStorageKeyProfileStatus, profile_status.toString());
    window.localStorage.setItem('__currency_type__','Yuan');
    window.localStorage.setItem('__language__','Chinese');
    
}



export function getProfileStatusFromLocalStorage() {
    
    const profileStatus = window.localStorage.getItem(localStorageKeyProfileStatus);
    
    if (!profileStatus) {
        return null;
    }

    return profileStatus;
}

export function storeProfileStatusInLocalStorage(profileStatus:ProfileStatusType) {
    window.localStorage.setItem(localStorageKeyProfileStatus, profileStatus.toString());    
}
  
function uri(baseUri: string, endpoint: string) {
    return `${trimTrailingSlash(baseUri)}/${trimLeadingSlash(endpoint)}`;
}
  
export function endpoint(endpoint: string) {
    const baseUri = localStorage.getItem(localStorageKeyBaseUri);

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    return uri(baseUri, endpoint);
}








