import {client} from "../client";
import { ActionResponse } from "../../contracts/response-models/action-response";
import { CartItem } from "../../contracts/response-models/cart";
 
function getCartItems(){ 
    return client<ActionResponse<CartItem>>(`/getcart`);
}

export { getCartItems }

