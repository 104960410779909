import { useState } from "react";
import { useHistory } from "react-router-dom";
import { storeTokenInLocalStorage } from "../api/client";
import { validateCellService, validateOTPService } from "../machines/admin/services";
import { GetLocalLangValue } from "../utils/language";

interface SignInParams {
  setLoginModalOpen:any;
}

enum ViewType {
  Login,
  VerifyOTP  
}



export default function Sign_in_form({setLoginModalOpen}:SignInParams) {
  
  const [cell,setCell] = useState('');
  const [code,setCode] = useState('');
  const [isLoading,setLoading] = useState(false);
  const [error,setError] = useState(undefined);
  const [currentView,setCurrentView] = useState<ViewType>(ViewType.Login);//LOGIN | VERIFY_OTP
  const history=useHistory();
  const [errorColor,setErrorColor]=useState('py-2');
  

  const validateCell = async ()=>{
      setLoading(true);
      setError(undefined);
      setCode('');
      try {

        const result = await validateCellService(cell); 
        
        setCurrentView(ViewType.VerifyOTP);
        
      } catch (e:any) {
         setError(e.message);
         
      }          
      setLoading(false);  
      
   }
   

   const validateOTP = async ()=>{
    setLoading(true);
    setError(undefined);
    
    try {

      const result = await validateOTPService(cell,code); 
      storeTokenInLocalStorage("https://api.lihongart.com/",result.access_token,result.isAdmin,result.profile_status);
      //setDeviceCookie();
      window.location.reload();
      setLoginModalOpen(false);   
    } catch (e:any) {
        setErrorColor('py-2 text-red-700');
        setError(e.message);
       
    }          
    setLoading(false);  
    
 }


  return (
    <div className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
   
      <div className="sm:mx-auto sm:w-full sm:max-w-md">        
        <h2 className="mt-2 text-center text-3xl font-bold text-gray-900">{GetLocalLangValue("SignIn")}</h2>
                
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          
          {currentView == ViewType.Login && (
           <div className="space-y-6" >
              <div>
                <label htmlFor="cell" className="block text-sm font-medium text-gray-700">
                 {GetLocalLangValue("EnterYourCell")}
                </label>
                <div className="mt-1">
                  <input
                    id="cell"
                    name="cell"
                    onChange={(e)=>{
                    setCell(e.target.value);
                    }}
                    type="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>            
              <div>
                <button 
                  onClick={validateCell}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                     {isLoading && (
                    <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                  )} 
                    {GetLocalLangValue("SendOTP")}
                </button>   
                     
              </div>
            </div>
          )}

          {currentView == ViewType.VerifyOTP && (
            <div className="space-y-6">
              
            <div>
              <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
              {GetLocalLangValue("EnterYourOTP")}
              </label>
              <div className="mt-1">
                <input                  
                 onChange={(e)=>{
                  setCode(e.target.value);
                  }}
                  type="text"
                  autoComplete="off"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>            
            <div>
              <button 
                onClick={validateOTP}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {isLoading && (
                    <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                  )} 
                  {GetLocalLangValue("Verify")}
              </button>   
              
            </div>
            </div>
          )}
          <div className={errorColor}>            
                {error && (
                  <span>{error}</span>
                )}  
          </div>
      </div>
      </div>
             
     </div>
  )
}



