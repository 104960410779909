import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import LoginModal from './LoginModal'
import Navigation from './Navigation'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import MyAccount from '../popover/myAccount'
import { hasValidToken } from '../utils/has-valid-token'
import CartSummary from './ShoppingCart/CartSummary'
import Language from '../popover/Language'
import PriceType from '../popover/Price'
import { GetLocalLangValue } from '../utils/language'



export default function Header() {
  
  const [loginModal,setLoginModalOpen] = useState(false); 
  
  if(loginModal)
      return (
        <LoginModal open={loginModal} setOpen={setLoginModalOpen} />
      )
  
  return (
      
    <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 sm:mr-48 text-3xl">
            <a href="/">
              <span className="sr-only">Lihongart</span>
              LihongArt
            </a>
          </div> 
          <div className="-mr-2 -my-2 md:hidden">
         
            <div className="bg-white  p-2 inline-flex items-center justify-center ">
              <CartSummary/>
              <a role="button" className="text-base font-medium text-black-500 hover:text-gray-500">
                <NavLink to='/order-summary'>{GetLocalLangValue("OrderArchives")}</NavLink>
              </a>
            </div>
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
          {<Navigation/> }
            <Popover className="relative">
                <>           
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                   
                  </Transition>
                </>
             
            </Popover>
            
          </Popover.Group>
          
          

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
             
            <CartSummary/>
            <a role="button" className="text-base font-medium text-black-500 hover:text-gray-500">
              <NavLink to='/order-summary'>{GetLocalLangValue("OrderArchives")}</NavLink>
            </a>
            <Language/>
            {!hasValidToken() ? (<a role='button'
             onClick={()=>(setLoginModalOpen(!loginModal))}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {GetLocalLangValue("SignIn")}
            </a>)
          :
          (<>
          <PriceType/>
          <MyAccount/>
          
          </>)  
          }
          
            
          </div>
        </div>
       
      </div>     
      


      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div className="text-3xl">
                <a href="#">
                  <span className="sr-only">Lihongart</span>
                  LihongArt
                  </a>
                  {/*
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />*/}
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                
                 <Navigation />
                 

              </div>
              {!hasValidToken() ?
              (<div>
                <a
                  role="button"
                  onClick={()=>(setLoginModalOpen(!loginModal))} 
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  {GetLocalLangValue("SignIn")}
                </a>
                </div>
                ):(<div> 
                 <Language/>
                 <PriceType/> 
                <a role="button" className="text-base font-medium text-gray-500 hover:text-gray-900">
                <NavLink to='/profile'>{GetLocalLangValue("Profile")}</NavLink>
                </a>
                <br></br>
                  
                <br></br>
                <a role="button" className="text-base font-medium text-gray-900 hover:text-gray-500"
                onClick={()=>{
                  localStorage.clear();
                  window.localStorage.setItem('__language__','Chinese');
                  window.location.reload();
                }}>
                {GetLocalLangValue("SignOut")}
                </a>
                </div>
               )
              }
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
 
  )
}
