import { useEffect, useState } from "react";
import { Orders } from "../contracts/response-models/order";
import { getOrderSummaryService } from "../machines/admin/services";
import { GetLocalLangValue } from "../utils/language";
import OrderRow from "./OrderRow";

const products = [
    {
      id: 1,
      name: 'Cold Brew Bottle',
      description:
        'This glass bottle comes with a mesh insert for steeping tea or cold-brewing coffee. Pour from any angle and remove the top for easy cleaning.',
      href: '#',
      quantity: 1,
      price: '$32.00',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-05-product-01.jpg',
      imageAlt: 'Glass bottle with black plastic pour top and mesh insert.',
    },
  ]
  
export default function OrderSummaryForm() {

    const [updatingSummary,setUpdatingSummary] = useState(false);
    
    
    const [orderItems, setOrderitems] = useState<Orders[]>();
    const currencyType=window.localStorage.getItem('__currency_type__');
    
    useEffect(() => {
        async function loadOrderSummary() {     
                       
            setUpdatingSummary(true);

            const result = await getOrderSummaryService();
            setOrderitems(result.data) ;
            
            setUpdatingSummary(false);
        }
        
        loadOrderSummary();
        
      }, []);
      
    return (  
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 sm:py-8 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-xl font-semibold uppercase tracking-wide text-black-600">{GetLocalLangValue("MyOrders")}</h1>
            {/*<p className="mt-2 text-base text-gray-500">Your order #14034056 has shipped and will be with you soon.</p>
  
            <dl className="mt-12 text-sm font-medium">
              <dt className="text-gray-900">Tracking number</dt>
              <dd className="text-indigo-600 mt-2">51547878755545848512</dd>
            </dl> */}
          </div>
  
          <div className="mt-2 border-t border-gray-200">
            <h2 className="sr-only">Your order</h2>
  
            <h3 className="sr-only">Items</h3>
           
              <div  className="border-b border-gray-200 flex space-x-6">
                <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                         
                          <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-900  tracking-wider">
                            {GetLocalLangValue("OrderId")}
                          </th>
                         
                          <th scope="col" className=" px-6 py-3 text-left text-sm font-medium text-gray-900 tracking-wider">
                          {GetLocalLangValue("OrderTotal")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-sm font-medium text-gray-900 tracking-wider">
                          {GetLocalLangValue("OrderDate")}
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">View Details</span>
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <>
                      {orderItems && orderItems.map((orderInfo) => (
                        <OrderRow orderInfo={orderInfo} currencyType={currencyType}  /> 
                      ))}                                              
                      </>
                      </tbody>
                    </table>
                    
            </div>            
            
          </div>
        </div>
      </div>
    )
  }
