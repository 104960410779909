import { useEffect, useState } from "react";
import { ProductFront } from "../../contracts/response-models/productfront";
import ProductBox from "./ProductBox";
import ProductList from "./ProductList";

interface ProductViewProps{
    products:ProductFront[]
}

enum ViewType{
    ListView,
    BoxView
}
export default function ProductView({products}:ProductViewProps){
    const [currentView,setCurrentView] = useState<ViewType>(ViewType.BoxView);
    const [language,setLanguage]=useState<any>();
    const [priceType,setPriceType]=useState<any>();
    useEffect(() => {
      async function loadCartInfo() {
        const lan= window.localStorage.getItem('__language__');
        const price= window.localStorage.getItem('__currency_type__');
        setLanguage(lan);
        setPriceType(price);
      }
      
      loadCartInfo();
      
    }, []);
    

    return(
        <>
            {
            products.map((product)=>(                
                <>
                { currentView == ViewType.ListView ? 
                    <ProductList product={product} currentView={currentView} setView={setCurrentView} language={language} priceType={priceType}/>                
                    : <ProductBox product={product} currentView={currentView} setView={setCurrentView} language={language} priceType={priceType} />                
                }
                </>
            ))}     
      
        </>
    )
}