import ProfileForm from '../components/profile-form';



 export default function ProfilePage () {
     
     return(
        
         <ProfileForm/>
        
     )
 }